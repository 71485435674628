import router from "@/router";
import { CAMPAIGN_WELCOME_SCREEN } from "@/common/dynamic-property/group-settings/campaign-welcome-screen";
import moment from "moment";
import ConfirmDialog from "@/view/pages/ConfirmDialog";

export default {
  props: {
    modal: {
      default: false
    },
    value: Boolean,
    item: {},
    cities: null,
    countries: null,
    canDelete: false
  },
  name: "welcome-screen-list",
  components: {
    ConfirmDialog
  },
  data() {
    return {
      dateFormat: "DD-MM-YYYY HH:mm",
      productNames: CAMPAIGN_WELCOME_SCREEN.PROPERTY_NAMES
    };
  },

  methods: {
    onGoToForm(value) {
      router.push({ name: "welcomeListEdit", params: { edit: value, isEdit: true } });
    },
    cutText(text, maxLength) {
      if (text.length > maxLength) {
        return text.slice(0, maxLength) + "...";
      }
      return text;
    },
    formatDate(date, format) {
      if (!date) return "";
      return moment
        .utc(date)
        .local()
        .format(format || this.dateFormat);
    },
    getCityById(id) {
      if (!id) return "";
      return this.cities.find(c => c.id === id)?.name || id;
    },
    getCountryById(id) {
      if (!id) return "";
      return this.countries.find(c => c.id === id)?.name || id;
    },
    onDeleteSetting(item) {
      this.$emit("onDelete", item);
    }
  }
};
