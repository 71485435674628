import { DYNAMIC_PROPERTY } from "../dynamic-property";

const PROPERTY_GROUP = "CAMPAIGN_WELCOME_SCREEN";

const PROPERTY_NAMES = {
  HEADER_IMAGE: "HEADER_IMAGE",
  MAIN_TITLE: "MAIN_TITLE",
  TOP_PARAGRAPH: "TOP_PARAGRAPH",
  BOTTOM_PARAGRAPH: "BOTTOM_PARAGRAPH",
  BUTTON_TEXT: "BUTTON_TEXT",
  BELOW_BUTTON_TEXT: "BELOW_BUTTON_TEXT"
};

const SETTING_TYPE = {
  DEFAULT_SETTING: "DEFAULT_SETTING",
  CITY_DEFAULT_SETTING: "CITY_DEFAULT_SETTING",
  CITY_SETTING: "CITY_SETTING"
};

const VALUE_MAP = {
  [PROPERTY_NAMES.HEADER_IMAGE]: DYNAMIC_PROPERTY.VALUE_TYPES.IMAGE,
  [PROPERTY_NAMES.TOP_PARAGRAPH]: DYNAMIC_PROPERTY.VALUE_TYPES.TEXT,
  [PROPERTY_NAMES.BOTTOM_PARAGRAPH]: DYNAMIC_PROPERTY.VALUE_TYPES.TEXT,
  [PROPERTY_NAMES.MAIN_TITLE]: DYNAMIC_PROPERTY.VALUE_TYPES.TEXT,
  [PROPERTY_NAMES.BUTTON_TEXT]: DYNAMIC_PROPERTY.VALUE_TYPES.TEXT,
  [PROPERTY_NAMES.BELOW_BUTTON_TEXT]: DYNAMIC_PROPERTY.VALUE_TYPES.TEXT
};

const ORDER_MAP = [
  PROPERTY_NAMES.HEADER_IMAGE,
  PROPERTY_NAMES.MAIN_TITLE,
  PROPERTY_NAMES.TOP_PARAGRAPH,
  PROPERTY_NAMES.BOTTOM_PARAGRAPH,
  PROPERTY_NAMES.BUTTON_TEXT,
  PROPERTY_NAMES.BELOW_BUTTON_TEXT
];

/**
 * A dynamic campaign welcome screen is a user interface designed to engage and welcome users to a specific campaign or promotion. This welcome screen serves as the entry point for participants, providing a personalized and interactive experience.
 * Welcoming Message, Personalized Greeting, Campaign Information, Code Entry Section, Call to Action, Referral Code...
 */
export const CAMPAIGN_WELCOME_SCREEN = {
  PROPERTY_GROUP,
  PROPERTY_NAMES,
  VALUE_MAP,
  ORDER_MAP,
  SETTING_TYPE
};
