import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import router from "@/router";
import moment from "moment";
import { CAMPAIGN_WELCOME_SCREEN } from "@/common/dynamic-property/group-settings/campaign-welcome-screen";
import SettingsApiService from "@/core/services/api.service.settings";
import ApiService from "@/core/services/api.service";
import ConfirmDialog from "@/view/pages/ConfirmDialog";
import AdditionalInfoDialog from "./welcome-screen-additional-info";
import WelcomeScreenItem from "./welcome-screen-list-item.vue";
import SchedulerApiService from "@/core/services/api.service.scheduler";

const defaultSetting = CAMPAIGN_WELCOME_SCREEN.SETTING_TYPE.DEFAULT_SETTING;
const SELECTED_COUNTRYID_KEY = "wc_selected_countryId";

export default {
  props: {
    modal: {
      default: false
    },
    value: Boolean
  },
  name: "welcome-screen-list",
  components: {
    ConfirmDialog,
    AdditionalInfoDialog,
    WelcomeScreenItem
  },
  beforeRouteLeave(to, from, next) {
    console.log("Leaving route", from.fullPath, "to", to.fullPath);
    // Perform actions before leaving the route
    if (!to.fullPath.includes("welcome-screen")) {
      localStorage.removeItem(SELECTED_COUNTRYID_KEY);
    }
    next();
  },
  data() {
    return {
      clusterDefaultList: {},
      countryDefaultList: {},
      groupedList: [],
      cities: [],
      countries: [],
      loading: false,
      productNames: CAMPAIGN_WELCOME_SCREEN.PROPERTY_NAMES,
      selectedCountry: null,
      selectedCity: null,
      citySearch: ""
    };
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.name }]);
    await ApiService.init();
    await SchedulerApiService.init();

    this.getCities();
    await this.getCountries();
    this.getSettingsGroupList({
      countryId: this.selectedCountry?.id || null
    });
  },
  methods: {
    onGoToNew() {
      router.push({ name: "welcomeListNew", params: { isEdit: false } });
    },
    getCities(params = "") {
      ApiService.get("api/cities", params).then(response => {
        this.cities = response.data || [];
      });
    },
    getCountries() {
      return SchedulerApiService.getCountries().then(response => {
        this.countries = response.data || [];
        this.getActiveCountry();
      });
    },
    getCountryFromLocalStorage() {
      const countryId = localStorage.getItem(SELECTED_COUNTRYID_KEY)
        ? localStorage.getItem(SELECTED_COUNTRYID_KEY)
        : localStorage.getItem("country_id");
      return JSON.parse(countryId);
    },
    getActiveCountry() {
      const findById = this.getCountryFromLocalStorage();
      this.selectedCountry = this.countries.find(item => item.id === findById);
    },
    getSettingsGroupList(params = {}) {
      this.loading = true;
      SettingsApiService.getSettingsGroup(CAMPAIGN_WELCOME_SCREEN.PROPERTY_GROUP, params)
        .then(response => {
          if (!response.data) return;

          this.groupList(response.data || []);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onSelectCountry(country) {
      if (!country?.id) return;

      this.loading = true;
      SchedulerApiService.getCities({
        countryId: country.id
      })
        .then(response => {
          this.cities = response.data || [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    filterSettingsGroupList() {
      const params = {
        countryId: this.selectedCountry?.id || null,
        cityId: this.selectedCity?.id || null
      };

      localStorage.setItem(SELECTED_COUNTRYID_KEY, this.selectedCountry.id);
      this.getSettingsGroupList(params);
    },
    openAdditionalInfo() {
      this.$refs.infoDialog.open();
    },
    groupList(data = []) {
      this.clusterDefaultList = this.groupClusterDefaultList(data);
      this.countryDefaultList = this.groupCountryDefaultList(data);
      const customList = this.getCustomSettingList(data);
      this.groupedList = this.getGroupedCustomSettingsList(customList);
    },
    groupClusterDefaultList(data = []) {
      const defaultList = this.constructDefaultObject();

      data.forEach(item => {
        if (item.settingType === defaultSetting && !item.countryId && !item.cityId) {
          // HANDLE DEFAULT
          defaultList[item.propertyName] = item;
        }
      });

      return defaultList;
    },
    groupCountryDefaultList(data = []) {
      const defaultList = this.constructDefaultObject();

      data.forEach(item => {
        if (item.settingType === defaultSetting && item.countryId && !item.cityId) {
          // HANDLE DEFAULT
          defaultList[item.propertyName] = item;
          defaultList.countryId = item.countryId;
        }
      });

      return defaultList;
    },
    constructDefaultObject() {
      // FORMAT OBJECT
      const defaultList = {};
      defaultList["cityId"] = null;
      defaultList["countryId"] = null;
      defaultList["settingType"] = defaultSetting;
      defaultList["validFrom"] = null;
      defaultList["validUntil"] = null;

      return defaultList;
    },
    getCustomSettingList(data = []) {
      return data.filter(
        item => item.settingType !== CAMPAIGN_WELCOME_SCREEN.SETTING_TYPE.DEFAULT_SETTING
      );
    },
    getGroupedCustomSettingsList(data = []) {
      const groupedList = [];

      data.forEach(item => {
        // CHECK IF KEY EXISTS
        const foundItem = groupedList.find(gItem => {
          return (
            gItem.countryId === item.countryId &&
            gItem.cityId === item.cityId &&
            gItem.settingType === item.settingType &&
            gItem.validFrom === item.validFrom &&
            gItem.validUntil === item.validUntil
          );
        });

        // APPLY VALUES
        if (foundItem) {
          foundItem[item.propertyName] = item;
        } else {
          //FORMAT OBJECT
          groupedList.push({
            countryId: item.countryId,
            cityId: item.cityId,
            settingType: item.settingType,
            validFrom: item.validFrom,
            validUntil: item.validUntil,
            [item.propertyName]: item
          });
        }
      });

      //sort list
      const cityDefaultSettings = CAMPAIGN_WELCOME_SCREEN.SETTING_TYPE.CITY_DEFAULT_SETTING;
      const sorted = groupedList.sort((a, b) => {
        const cityIdResult = a?.cityId - b?.cityId;

        if (cityIdResult !== 0) {
          return cityIdResult;
        }

        const isDefaultResult = a?.settingType === cityDefaultSettings;

        if (isDefaultResult) {
          return -1;
        }

        let startDateResult = 0;

        if (a?.validFrom && b?.validFrom) {
          const start = moment(a.validFrom);
          const end = moment(b.validFrom);
          startDateResult = start.isBefore(end) ? -1 : 1;
        }

        return startDateResult;
      });

      return sorted;
    },

    async onDeleteSetting(item) {
      if (
        await this.$refs.confirm.open(
          this.$i18n.t("common.confirm"),
          this.$i18n.t("common.areYouSure") + "?"
        )
      ) {
        // GET ID MAP
        const ids = Object.keys(item)
          .map(key => item[key]?.id)
          .filter(item => item)
          .join(",");

        const group = CAMPAIGN_WELCOME_SCREEN.PROPERTY_GROUP;

        SettingsApiService.deleteSetting(group, ids).then(() => {
          this.groupedList = this.groupedList.filter(fItem => fItem !== item);
        });
      }
    }
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo", "currentUser"]),
    name() {
      return this.$i18n.t("menu.welcomeScreen");
    }
  }
};
