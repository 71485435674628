<template>
  <v-dialog v-model="show" max-width="650px">
    <v-card>
      <v-card-title class="text-h5">
        {{ $t("welcomeScreen.fieldPlacement") }}
      </v-card-title>

      <div class="p-2">
        <v-img
          src="@/assets/images/tutorials/welcome-screen.png"
          width="100%"
          height="auto"
        ></v-img>
      </div>

      <div class="pl-4 pr-2 info-box">
        <v-icon class="text-primary">mdi-information</v-icon>
        {{ $t("welcomeScreen.campaignInfo") }}
      </div>
      <v-card-actions class="d-flex justify-content-end">
        <v-btn text @click="close">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    modal: {
      default: false
    },
    value: Boolean
  },
  name: "welcome-screen-additional-info",
  data() {
    return {
      show: false
    };
  },
  methods: {
    open() {
      this.show = true;
    },
    close() {
      this.show = false;
    }
  }
};
</script>
<style lang="scss">
.info-box {
  font-size: 11px;
}
</style>
