export const DYNAMIC_PROPERTY = {
  VALUE_TYPES: {
    IMAGE: "IMAGE",
    HTML: "HTML",
    TEXT: "TEXT",
    DATETIME: "DATETIME",
    BOOLEAN: "BOOLEAN",
    NUMBER: "NUMBER"
  }
};
